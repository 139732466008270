<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mcompatibilidad-modals
			:modelName="modelName"
		></mcompatibilidad-modals>
	</div>
</template>

<script>
import mcompatibilidadActions from './McompatibilidadActions';
import mcompatibilidadModals from './McompatibilidadModals.vue';

export default {
	name: 'mcompatibilidad-grid',
	components: {
		'mcompatibilidad-modals': mcompatibilidadModals
	},
	data() {
		return {
			modelName: 'mcompatibilidad',
			actions: mcompatibilidadActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
